import 'primereact/resources/themes/lara-light-indigo/theme.css'; //theme
import 'primereact/resources/primereact.min.css'; //core css
import 'react-toastify/dist/ReactToastify.css';
import '@recx/auth/dist/index.css';
import './assets/styles/main.scss';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import data from './data/Translation.json';
import App from './App';

import { createRoot } from 'react-dom/client';

i18n.use(initReactI18next).init(data);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(<App />);
