export default function Loader({ variant = 'overlay' }) {
    const containerClass = variant === 'overlay' ? 'loader-overlay' : 'loader-static';
    const loaderClass = variant === 'overlay' ? 'loader console_loader' : 'loader';

    return (
        <div className={containerClass}>
            <div className={loaderClass}>
                <div className="loader-spinner"></div>
            </div>
        </div>
    );
}
