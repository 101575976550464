import { defaultMaxTokens, defaultTemperature } from 'constant';
import React from 'react';
import { createContext, useState } from 'react';
import { BotReply, ChatMessage, IBotDetail } from 'interface';
import { useFetch } from '@recx/auth';
import { AgentAssistContextType } from 'types/context';

export const AgentAssistContext = createContext<AgentAssistContextType>({} as AgentAssistContextType);

const AgentAssistProvider = ({ children }) => {
    const { post } = useFetch(process.env.REACT_APP_API_URL);

    const [testBotDetail, setTestBotDetail] = useState<IBotDetail>(null);
    const [agentReply, setAgentReply] = useState<BotReply>(null);
    const [selectedQuickAction, setSelectedQuickAction] = useState(null);
    const [showQuickActions, setShowQuickActions] = useState(true);
    const [isGeneralQuerySelected, setIsGeneralQuerySelected] = useState(false);
    const [botControllers, setBotControllers] = useState({});
    const [temperature, setTemperature] = useState(defaultTemperature);
    const [maxTokens, setMaxTokens] = useState(defaultMaxTokens);
    const [LLM_TYPES, setLLM_TYPES] = useState([]);
    const DefaultResponseLLM = LLM_TYPES.length > 0 ? LLM_TYPES[0] : 'azure-gpt4o-200b';

    const get_LLM = async () => {
        const response = await post('/Bot/GetLLMs', {});
        const isSuccess = response?.statusCode === 200;

        if (response?.statusCode === 200) {
            setLLM_TYPES(response?.data?.llms);
            //setTrainedBots(response?.data?.trainedBotDetails);
        } else {
            //t/oast.error(response.message);
        }
    };
    React.useEffect(() => {
        // get_LLM();
    }, []);
    const [sessionChatHistory, setSessionChatHistory] = useState<ChatMessage[]>([]);

    return (
        <AgentAssistContext.Provider
            value={{
                botControllers,
                setBotControllers,
                agentReply,
                setAgentReply,
                temperature,
                setTemperature,
                maxTokens,
                setMaxTokens,
                testBotDetail,
                setTestBotDetail,
                LLM_TYPES,
                sessionChatHistory,
                setSessionChatHistory,
                selectedQuickAction,
                setSelectedQuickAction,
                showQuickActions,
                setShowQuickActions,
                isGeneralQuerySelected,
                setIsGeneralQuerySelected,
            }}>
            {children}
        </AgentAssistContext.Provider>
    );
};

export default AgentAssistProvider;
