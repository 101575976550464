import { AccessDenied, Header, Layout, MiniSideBar, Outlet, SideBarItem, useAuthContext } from '@recx/auth';
import React, { Fragment } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import BotProvider from 'providers/BotProvider';
import { SessionProvider } from '../providers/SessionProvider';
import { ConfirmDialog } from 'primereact/confirmdialog';
import Loader from 'components/Loader';
import PermissionsProvider from 'providers/PermissionsProvider';
import useSignalRConnection from 'hooks/useSignalRConnection';
import SignalRConnectionProvider from 'providers/SignalRConnectionProvider';
import AgentAssistProvider from 'providers/AgentAssistProvider';
import { SurveyProvider } from 'providers/SurveyFlowProvider';

//const { sessionPermission } = useContext(PermissionContext);
const SideBarDefaultItems: SideBarItem[] = [
    {
        icon: 'fa-light fa-message-code',
        name: 'Bot Studio',
        route: 'botstudio',
        component: React.lazy(() => import('./BotStudio/BotList')),
        unique_id: 'report_info_bot',
        showInSidebar: true,
        description: '',
        children: [],
        child: false,
        externalLink: false,
        path: '/botstudio'
    },
    {
        icon: 'far fa-pencil-paintbrush',
        name: 'Editor',
        route: 'edit',
        externalLink: false,
        component: React.lazy(() => import('./BotStudio/Routes/BotEdit')),
        unique_id: 'report_info_bot',
        showInSidebar: false,
        description: '',
        path: '/edit/:botId',
        children: [
            {
                icon: 'far fa-pencil-paintbrush',
                name: 'Editor',
                route: 'edit',
                externalLink: false,
                component: React.lazy(() => import('./BotStudio/CreateBot/Sources')),
                unique_id: 'report_info_bot',
                showInSidebar: true,
                description: '',
                path: 'sources',
                children: [],
                child: true
            }
        ],
        child: false
    },
    // {
    //     icon: 'fa-solid fa-message-bot',
    //     name: 'Sessions',
    //     route: 'sessions/?',
    //     component: React.lazy(() => import('./Chat/LeftSidebar')),
    //     unique_id: 'report_info_bot',
    //     showInSidebar: true,
    //     description: '',
    //     child: false,
    //     externalLink: false,
    //     path: '/sessions/?',
 
    {
        icon: 'fa-solid fa-message-bot',
        name: 'Chat',
        route: 'conversebot/:botId',
        component: React.lazy(() => import('./Chat/TryoutChatbot')),
        unique_id: 'report_info_bot',
        showInSidebar: false,
        description: '',
        child: false,
        externalLink: false,
        path: '/conversebot/:botId',
        children: []
    },
    {
        icon: 'fa-solid fa-message-bot',
        name: 'Chat',
        route: 'conversebot/:sessionId',
        component: React.lazy(() => import('./Chat/TryoutChatbot')),
        unique_id: 'report_info_bot',
        showInSidebar: false,
        description: '',
        child: false,
        externalLink: false,
        path: '/conversebot/:sessionId',
        children: []
    },
];

const baseOriginUrl = process.env.REACT_APP_BASE_ORIGIN_URL;
const modules = ['feedback', 'converse', 'analyze'];
function Home() {
    const { routes, loading, permissionEvaluated } = useAuthContext();

    return (
        <Fragment>
            {loading && <Loader />}
            <Header {...{ toast, menuItems: [], baseOriginUrl }}></Header>
            <MiniSideBar {...{ routes }} />
            {permissionEvaluated && <AccessDenied {...{ apps: routes.filter((x) => x.canAccess), loading }} />}
            <PermissionsProvider>
                <SignalRConnectionProvider>
                    <BotProvider>
                        <AgentAssistProvider>
                            <SurveyProvider>
                                <SessionProvider>
                                    <Layout {...{ modules, SideBarDefaultItems, appName: process.env.REACT_APP_TITLE }}>
                                        <Outlet />
                                        <ConfirmDialog />
                                        <ToastContainer
                                            position={'bottom-right'}
                                            autoClose={2000}
                                            hideProgressBar={false}
                                            closeOnClick={true}
                                            pauseOnHover={true}
                                            draggable={true}
                                            pauseOnFocusLoss={false}
                                        />
                                    </Layout>
                                </SessionProvider>
                            </SurveyProvider>
                        </AgentAssistProvider>
                    </BotProvider>
                </SignalRConnectionProvider>
            </PermissionsProvider>
        </Fragment>
    );
}
export default Home;
