export const TOP_ANSWERS = [1, 2, 3];

export const defaulttopAnswers = TOP_ANSWERS[0];
export const defaultEmployeeCampaign = '';
export const defaultPipeline = 'Azure';

export const DOMAIN = 'generic';

export const BOT_TYPE = 'Business';
export const BOT_DISCLAIMER_LENGTH = 200;
export const BOT_NAME_MAX_LENGTH = 50;
export const BOT_PERSONALITY_MAX_LENGTH = 2000;
export const BOT_GREETING_MESSAGE_MAX_LENGTH = 250;
export const CHAT_QUERY_MAX_LENGTH = 100;

export const INTENT_NAME_MAX_LENGTH = 50;
export const INTENT_USERNAME_MAX_LENGTH = 20;
export const INTENT_PASSWORD_MAX_LENGTH = 20;
export const INTENT_DESCRIPTION_MAX_LENGTH = 250;
export const INTENT_SLOT_FORM_HEADER_MAX_LENGTH = 50;

export const INTENT_SLOT_QUESTION_MAX_LENGTH = 100;
export const INTENT_SLOT_DESCRIPTION_MAX_LENGTH = 250;
export const INTENT_SLOT_STATIC_OPTION_MAX_LENGTH = 30;
export const INTENT_SLOT_NAME_MAX_LENGTH = 30;
export const INTENT_SLOT_PARAM_NAME_MAX_LENGTH = 70;
export const INTENT_SLOT_FIXED_VALUE_MAX_LENGTH = 100;
export const INTENT_SLOT_PARSER_MAX_LENGTH = 100;
export const INTENT_REFERENCE_SLOT_PARSER_MAX_LENGTH = 100;

export const ADVANCED_SETTINGS_KNOWLEDGE_SOURCE_GENERATION_PROMPT_MAX_LENGTH = 1000;

export const INTENT_ACTION_STATIC_MESSAGE_MAX_LENGTH = 500;
export const INTENT_ACTION_RESPONSE_PARSE_MAX_LENGTH = 100;
export const INTENT_ACTION_ANSWER_GENERATION_MAX_LENGTH = 500;
export const INTENT_ACTION_CONDITION_MAX_LENGTH = 100;
export const INTENT_ACTION_PARSE_RESPONSE_STATIC_MESSAGE_MAX_LENGTH = 100;

export const PRIMARY_COLOR = '#4b4efc';

export const Source = {
    WEBSITE: 1,
    DOCUMENT: 2,
    API: 3
};

export const SourceTypes = ['Websites', 'Documents', 'APIs', 'Surveys'];

export const SurveyQuestionType = {
    COMMENT_LIST: 'Comment List',
    RATING: 'Rating',
    RADIO_LIST: 'Radio List',
    STAR_RATING: 'Star Rating',
    CHECKBOX_LIST: 'Checkbox List',
    LANG_SELECTION: 'System_Lang_Selection'
};

export const SurveyFlow = {
    START: 1,
    INTENT_BASED: 2,
    END: 3
};

export const defaultBotPersonality =
    'You are ReCX AI Customer Assistant. Your role is to handle basic customer queries and respond with precise answer.';
export const defaultGreetingMessage = 'Hi, I am ReCX AI Customer Assistant. How can I help you?';

// Bot Settings Defaults
export const defaultMaxTokens = 16;
export const defaultTemperature = 0;
export const defaultPublicAccess = false;
export const defaultPasswordProtected = false;
export const defaultSmallTalk = true;
export const defaultIsEnabledSessionExpiry = false;
export const defaultAssociateCampaign = false;
export const DefaultChatbotFont = 'Gilroy';
export const DefaultChatbotFontSize = 12;

// Document
export const DocFileExt = ['pdf', 'docx', 'txt'];
// export const DocFileExt = ['pdf', 'docx', 'zip', 'jpeg', 'xlsx', 'png', 'jpg', 'txt'];
export const MaxFileSizeLimit = 25 * 1_048_576; // 25MB

// API Form Field Registry
export const IntentFields = {
    IntentNameField: 'intentName',
    IntentTriggerField: 'intentTrigger',
    IntentDescriptionField: 'intentDescription',
    IntentTypeField: 'intentType',
    PromptTemplateField: 'PromptTemplate',
    ApiFields: {
        AuthenticationTypeField: 'authType',
        UsernameField: 'username',
        PasswordField: 'password',
        TokenUrlField: 'authUrl',
        UrlField: 'url',
        RequestTypeField: 'requestType',
        ApiMethodField: 'ApiMethod'
    },
    
} as const;

// export const IntentNameField = 'intentName';
// export const IntentTriggerField = 'intentTrigger';
// export const IntentDescriptionField = 'intentDescription';
// export const IntentTypeField = 'intentType';
// export const AuthenticationTypeField = 'authType';
// export const UsernameField = 'username';
// export const PasswordField = 'password';
// export const TokenUrlField = 'authUrl';
// export const urlField = 'url';
// export const RequestTypeField = 'requestType';
// export const ApiMethodField = 'ApiMethod';
// export const PromptTemplateField = 'PromptTemplate';

// Slot Form Field Registry
export const SlotFields = {
    SendToField: 'sendTo',
    SlotNameField: 'slotName',
    SlotRequestParamNameField: 'requestParamName',
    SlotTypeField: 'slotType',
    PreviousInputHandleField: 'previousInputHandle',
    MultiSelectorField: 'multiSelector',
    FixedValue: 'fixedValue',
    SlotFieldTypeField: 'slotFieldType',
    AddressZipCodeSlotField: 'addressZipCodeSlot',
    SlotFieldTypeMinField: 'slotFieldTypeMin',
    IsTextOnlyField: 'isTextOnly',
    SlotFieldTypeMaxField: 'slotFieldTypeMax',
    SlotDescriptionField: 'slotDescription',
    MultipleOptionsTypeField: 'multipleOptionsType',
    MultiSelector: 'multiSelector',
    multipleStaticOptionField: 'multipleStaticOption',
    MultipleSlotOptionField: 'multipleSlotOption',
    SlotParserField: 'slotParser',
    ReferenceSlotNameField: 'referenceSlotName',
    ReferenceSlotParserField: 'referenceSlotParser',
    CountryField: 'country',
    
} as const;

// Action Form Field Registry
export const ActionFields = {
    MessageTypeField: 'messageType',
    ConditionTypeField: 'conditionType',
    ActionTypeField: 'actionType',
    ActionStaticMessageField: 'actionStaticMessage',
    ActionTriggerApiField: 'actionTriggerApi',
    ConditionField: 'condition',
    StaticMessageField: 'staticMessage',
    ResponseParseField: 'responseParse',
    AnswerGenerationField: 'answerGeneration',
    StorageSlotField: 'storageSlot',
    StorageSlotParserField: 'storageSlotParser'
} as const;

// Dependent Intent Form Field Registry
export const IntentTriggerStartField = 'intentTriggerStart';
export const IntentTriggerEndField = 'intentTriggerEnd';

export const sourceTypeIcons = {
    document: 'source-icon fa-regular fa-file-pdf',
    website: 'source-icon fa-regular fa-browser',
    api: 'source-icon fa-regular fa-sitemap'
};

// Advance Bot Settings
// #chunk technique
export const minValueChunk = 0;
export const maxValueChunk = 500;

export const minValueWindowsize = 0;
export const maxValueWindowsize = 50;

// parent chunk
export const minValueParentChunkSize = 0;
export const maxValueParentChunkSize = 1200;

export const minValueParentWindowsize = 0;
export const maxValueParentWindowsize = 100;

// retriever
export const minValueThreshold = 0;
export const maxValueThreshold = 1;

export const minValueNumberofChunks = 1;
export const maxValueNumberofChunks = 4;

// temperarture
export const minValueTemperature = 0;
export const maxValueTemperature = 1;
