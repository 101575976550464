import { useAuthContext } from '@recx/auth';
import { createContext } from 'react';

export const PermissionContext = createContext<any>({});

const PermissionsProvider = ({ children }) => {
    const { permissions } = useAuthContext();

    const hasPermission = (permission: string) => {
        return permissions.some((report): boolean => report.shortName === permission);
    };

    return (
        <PermissionContext.Provider
            value={{
                readBotPermission: hasPermission('report_info_bot.read'),
                createBotPermission: hasPermission('report_info_bot.create'),
                deleteBotPermission: hasPermission('report_info_bot.delete'),
                updateBotPermission: hasPermission('report_info_bot.update'),
                chatBotPermission: hasPermission('report_info_bot.chat'),
                sessionPermission: hasPermission('report_session_bot.read'),
                trainBotPermission: true
            }}>
            {children}
        </PermissionContext.Provider>
    );
};

export default PermissionsProvider;
