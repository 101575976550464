import { AuthContainer, HttpProvider } from '@recx/auth';
import logo from 'assets/images/logo.svg';
import loader from 'assets/images/loader.gif';
import Home from 'screens/Home';
import 'react-datepicker/dist/react-datepicker';
import 'react-phone-number-input/style.css'

const REACT_APP_NAME = process.env.REACT_APP_NAME;
const API_URL = process.env.REACT_APP_API_URL;

function App() {
    return (
        <AuthContainer
            {...{
                baseUrl: `/${REACT_APP_NAME}`,
                baseOriginUrl: process.env.REACT_APP_BASE_URL,
                client: 'SSO',
                identityServerUrl: process.env.REACT_APP_IDENTITY_URL,
                logo,
                loader
            }}>
            <HttpProvider {...{ apiUrl: API_URL }}>
                <Home />
            </HttpProvider>
        </AuthContainer>
    );
}

export default App;
