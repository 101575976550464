import { useState } from 'react';

const useDrawer = (): [drawerOpen: boolean, toggleDrawer: (value: boolean, e?: any) => void] => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const toggleDrawer = (value: boolean, event?: any) => {
        if (
            event &&
            event?.type === 'keydown' &&
            (event?.key === 'Tab' || event?.key === 'Shift')
        ) {
            return;
        }
        setDrawerOpen(value);
    };

    return [drawerOpen, toggleDrawer];
};

export default useDrawer;
