import { SurveyQuestionType } from 'constant';
import { createContext, useCallback, useContext, useState } from 'react';
import { addUserMessage, InputEnabled, InputDisabled } from '@recx/chat';
import { useTranslation } from 'react-i18next';
export const SurveyContext = createContext<any>({});

export const SurveyProvider = ({ children }) => {
    const { t, i18n } = useTranslation();
    const [convo, setConvo] = useState([]);
    const [Questionid, setQuestionId] = useState(null);
    const [inputDisabled, setInputDisabled] = useState(false);
    const [messageLoader, setMessageLoader] = useState(false);
    const [statesurveyStatus, setSurveyStatus] = useState(null);
    const [surveylanguages, setSurveyLanguages] = useState([]);
    const [statesessionId, setSessionId] = useState(null);

    const InputEnabledChat = () => {
        InputEnabled();
    };

    const InputDisabledChat = () => {
        InputDisabled();
    };

    const HandleResponse = useCallback(
        (e: any, optionIndex, item: any, skip: boolean = false, undo: boolean = false, handleNewUserMessage) => {
            try {
                if (!undo) {
                    let message = !skip
                        ? item.question_type === SurveyQuestionType.COMMENT_LIST
                            ? e
                            : e.map((x) => x.label)
                        : [t('SKIP')];

                    var clientMessageList = null;
                    var clientMessage = message;

                    if (message.length > 1) {
                        clientMessageList = message;
                        clientMessage = clientMessageList.join();
                    } else {
                        clientMessage = message[0];
                    }

                    if (item.question_type === SurveyQuestionType.COMMENT_LIST && skip) {
                        clientMessage = t('SKIP');
                    }

                    /* COMMETING LANGUAGE SELECTION FLOW FOR NOW */

                    if (item.question_name === SurveyQuestionType.LANG_SELECTION) {
                        var languageCode = surveylanguages?.find((x) => x.label?.trim() === e[0].label?.trim())?.value;
                        if (languageCode) {
                            i18n.changeLanguage(languageCode);
                        }
                    }
                    addUserMessage(clientMessage);
                    handleNewUserMessage(clientMessage, skip, clientMessageList, inputDisabled);
                }
            } catch (ex) {
                console.trace(ex);
            }
        },
        [surveylanguages, inputDisabled, Questionid]
    );
    return (
        <SurveyContext.Provider
            value={{
                convo,
                setConvo,
                HandleResponse,
                Questionid,
                inputDisabled,
                setQuestionId,
                setSurveyStatus,
                setSurveyLanguages,
                setSessionId,
                statesessionId,
                statesurveyStatus,
                surveylanguages,
                t,
                messageLoader,
                InputEnabledChat,
                InputDisabledChat
            }}>
            {children}
        </SurveyContext.Provider>
    );
};

const useSurveyProvider = () => useContext(SurveyContext);

export default useSurveyProvider;
