import useSignalRConnection from 'hooks/useSignalRConnection';
import { createContext } from 'react';
import { HubConnection } from '@microsoft/signalr';

type HubConnections = {
    webhookConnection: HubConnection;
    converseConnection: HubConnection;
};

export const SignalRContext = createContext<HubConnections>(null);

const SignalRConnectionProvider = ({ children }) => {
    const webhookConnection = useSignalRConnection(`${process.env.REACT_APP_WEBHOOK_HUB_URL}hubs/botstatus`);
    const converseConnection = useSignalRConnection(`${process.env.REACT_APP_CONVERSE_HUB_URL}hubs/botstatus`);

    return (
        <SignalRContext.Provider
            value={{
                webhookConnection,
                converseConnection
            }}>
            {children}
        </SignalRContext.Provider>
    );
};

export default SignalRConnectionProvider;
